<template>
  <div class="form-group">
    <div>
      <label>{{ t("components.visible_to_customers_selector.label") }}</label>
    </div>
    <div class="btn-group btn-multiple">
      <div class="btn btn-primary" :class="{ active: !modelValue }" @click="updateValue(null)">
        {{ t("components.visible_to_customers_selector.all_customers") }}
      </div>
      <div class="btn btn-primary" :class="{ active: modelValue == 'standard' }" @click="updateValue('standard')">
        {{ t("components.visible_to_customers_selector.standard_customers") }}
      </div>
      <div class="btn btn-primary" :class="{ active: modelValue == 'limited' }" @click="updateValue('limited')">
        {{ t("components.visible_to_customers_selector.limited_customers") }}
      </div>
      <!-- <div class="btn btn-primary" :class="{ active: modelValue == 'selected' }" @click="updateValue('selected')">
        {{ t("components.visible_to_customers_selector.selected_customers") }}
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";

defineProps<{ modelValue?: string }>();

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};
</script>
